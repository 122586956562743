import React from 'react'
import styled from 'styled-components'
import { CompanyInfo } from '../constants'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .price-wrapper {
    padding: 0 3rem 3rem 3rem;
  }

  p {
    text-align: left;
  }

  .header {
    margin-top: 4rem;
  }

  ul {
    margin-top: 4rem;
    padding-left: 1.5rem;
  }

  @media (max-width: 576px) {
    td {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 350px) {
    td {
      font-size: 1rem;
    }
  }
`

const PricePage = () => (
  <Wrapper>
    <div className="price-wrapper">
      <h1>ПРАЙС НА ПРОЖИВАНИЕ</h1>

      <p>
        Прайс можно скачать по{' '}
        <a href={CompanyInfo.price} target="_blank">
          ссылке
        </a>
        .
      </p>
    </div>
  </Wrapper>
)

export default PricePage
